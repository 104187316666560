<template>
  <div v-if="policy" class="forms-elements">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />

    <b-tabs content-class="" fill v-model="tabIndex">
      <b-tab :title="$t('policies')" active>
        <div class="mt-3">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="d-flex gap-3 align-items-center">
              <b-form-group class="mb-0">
                <multiselect
                  v-model="filters.role"
                  :options="roleOptions"
                  :placeholder="$t('role') + ' ' + $t('filter')"
                  :allow-empty="true"
                  :show-labels="false"
                  :taggable="false"
                  @input="handleFiltersChange"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    <b-badge variant="info">{{ $t(option) }}</b-badge>
                  </template>
                  <template slot="option" slot-scope="{ option }">
                    <b-badge variant="info">{{ $t(option) }}</b-badge>
                  </template>
                </multiselect>
              </b-form-group>

              <b-form-group class="mb-0">
                <multiselect
                  v-model="filters.department"
                  :options="departmentOptions"
                  :placeholder="$t('department') + ' ' + $t('filter')"
                  :allow-empty="true"
                  :show-labels="false"
                  :taggable="false"
                  @input="handleFiltersChange"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    <b-badge variant="warning">{{ $t(option) }}</b-badge>
                  </template>
                  <template slot="option" slot-scope="{ option }">
                    <b-badge variant="warning">{{ $t(option) }}</b-badge>
                  </template>
                </multiselect>
              </b-form-group>

              <b-form-group class="mb-0">
                <multiselect
                  v-model="filters.resourceType"
                  :options="getEnumValues('ResourceType')"
                  :placeholder="$t('resourceType') + ' ' + $t('filter')"
                  :allow-empty="true"
                  :show-labels="false"
                  :taggable="false"
                  @input="handleFiltersChange"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    <b-badge variant="primary">{{ $t(option) }}</b-badge>
                  </template>
                  <template slot="option" slot-scope="{ option }">
                    <b-badge variant="primary">{{ $t(option) }}</b-badge>
                  </template>
                </multiselect>
              </b-form-group>

              <b-form-group class="mb-0">
                <multiselect
                  v-model="sortOption"
                  :options="sortOptions"
                  :placeholder="$t('sort_by')"
                  :allow-empty="false"
                  :show-labels="false"
                  :taggable="false"
                  @input="handleSortChange"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    <span><i :class="option.icon"></i> {{ $t(option.label) }}</span>
                  </template>
                  <template slot="option" slot-scope="{ option }">
                    <span><i :class="option.icon"></i> {{ $t(option.label) }}</span>
                  </template>
                </multiselect>
              </b-form-group>
            </div>
            <div class="d-flex gap-3 align-items-center">
              <b-button variant="inverse" @click="resetFilters">
                <i class="fa fa-times"></i> {{ $t('remove_filters') }}
              </b-button>
              <b-button variant="primary" @click="createNewPolicy">
                <i class="fa fa-plus"></i> {{ $t('createPolicy') }}
              </b-button>
            </div>
          </div>
          <hr />

          <div class="policy-grid">
            <div v-for="policy in filteredPolicies" :key="policy._id" class="policy-section p-card">
              <div class="policy-card">
                <div class="policy-card-content">
                  <div class="policy-info-row">
                    <div class="policy-info-label">{{ $t('role') }}:</div>
                    <div class="policy-info-value">
                      <multiselect
                        :placeholder="$t('role') + ' ' + $t('select')"
                        :value="policy.userConditions && policy.userConditions.role"
                        :options="roleOptions"
                        :show-labels="false"
                        :allow-empty="true"
                        :taggable="false"
                        :selectLabel="$t('select')"
                        :selectedLabel="$t('selected')"
                        @input="(value) => updateUserRole(policy, value)"
                      >
                        <template slot="singleLabel" slot-scope="{ option }">
                          <b-badge variant="info">{{ $t(option) }}</b-badge>
                        </template>
                        <template slot="option" slot-scope="{ option }">
                          <b-badge variant="info">{{ $t(option) }}</b-badge>
                        </template>
                      </multiselect>
                    </div>
                  </div>

                  <div class="policy-info-row">
                    <div class="policy-info-label">{{ $t('department') }}:</div>
                    <div class="policy-info-value">
                      <multiselect
                        :placeholder="$t('department') + ' ' + $t('select')"
                        :value="policy.userConditions && policy.userConditions.department"
                        :options="departmentOptions"
                        :show-labels="false"
                        :allow-empty="true"
                        :taggable="false"
                        :selectLabel="$t('select')"
                        :selectedLabel="$t('selected')"
                        @input="(value) => updateUserDepartment(policy, value)"
                      >
                        <template slot="singleLabel" slot-scope="{ option }">
                          <b-badge variant="warning">{{ $t(option) }}</b-badge>
                        </template>
                        <template slot="option" slot-scope="{ option }">
                          <b-badge variant="warning">{{ $t(option) }}</b-badge>
                        </template>
                      </multiselect>
                    </div>
                  </div>

                  <div class="policy-info-row">
                    <div class="policy-info-label">{{ $t('resourceType') }}:</div>
                    <div class="policy-info-value">
                      <multiselect
                        :placeholder="$t('resourceType') + ' ' + $t('select')"
                        :value="policy.resourceConditions && policy.resourceConditions.resourceType"
                        :options="getEnumValues('ResourceType')"
                        :show-labels="false"
                        :allow-empty="true"
                        :taggable="false"
                        :selectLabel="$t('select')"
                        :selectedLabel="$t('selected')"
                        @input="(value) => updateResourceType(policy, value)"
                      >
                        <template slot="singleLabel" slot-scope="{ option }">
                          <b-badge variant="primary">{{ $t(option) }}</b-badge>
                        </template>
                        <template slot="option" slot-scope="{ option }">
                          <b-badge variant="primary">{{ $t(option) }}</b-badge>
                        </template>
                      </multiselect>
                    </div>
                  </div>

                  <div class="policy-info-row">
                    <div class="policy-info-label">{{ $t('conditions') }}:</div>
                    <div class="policy-info-value">
                      <div class="d-flex align-items-center">
                        <b-form-checkbox
                          :checked="
                            policy.resourceConditions &&
                              policy.resourceConditions.userHasToBeAssignedToResource
                          "
                          @change="toggleAssignmentCondition(policy)"
                        >
                          <b-badge
                            :variant="
                              policy.resourceConditions &&
                              policy.resourceConditions.userHasToBeAssignedToResource
                                ? 'warning'
                                : 'white'
                            "
                          >
                            {{ $t('userHasToBeAssignedToResource') }}
                          </b-badge>
                        </b-form-checkbox>
                      </div>
                    </div>
                  </div>

                  <div class="policy-info-row">
                    <div class="policy-info-label">{{ $t('authorizableActions') }}:</div>
                    <div class="policy-info-value">
                      <div class="action-checkbox-group">
                        <div
                          v-for="action in getEnumValues('AuthorizableActions')"
                          :key="action"
                          class="action-checkbox-item"
                        >
                          <b-form-checkbox
                            :checked="
                              policy.actionConditions && policy.actionConditions.includes(action)
                            "
                            @change="toggleActionCondition(policy, action)"
                          >
                            {{ $t(action) }}
                          </b-form-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="d-flex align-items-center mb-0 justify-content-between">
                    <div class="mr-1 mb-0 policy-name">
                      {{ getPolicyDescription(policy) }}
                    </div>
                    <ConfirmDeleteButton
                      :id="policy.number"
                      :icon-only="true"
                      @confirm-delete-button:confirmed="deletePolicy"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <b-pagination
            v-model="currentPage"
            :total-rows="policyCount"
            :per-page="tableStateStore.pagination.pageSize"
            @change="loadTableData"
            align="center"
            class="mt-3"
          ></b-pagination>
        </div>
      </b-tab>

      <b-tab :title="$t('roles')">
        <div class="mt-3">
          <h5>{{ $t('roles') }}</h5>
          <div class="badge-container">
            <b-badge
              v-for="(role, index) in getEnumValues('UserRole')"
              :key="index"
              variant="info"
              class="badge-item"
            >
              {{ $t(role) }}
            </b-badge>
          </div>
        </div>
      </b-tab>

      <b-tab :title="$t('departments')">
        <div class="mt-3">
          <h5>{{ $t('departments') }}</h5>
          <div class="badge-container">
            <b-badge
              v-for="(department, index) in getEnumValues('Department')"
              :key="index"
              variant="warning"
              class="badge-item"
            >
              {{ $t(department) }}
            </b-badge>
          </div>
        </div>
      </b-tab>

      <b-tab :title="$t('resources')">
        <div class="mt-3">
          <h5>{{ $t('resources') }}</h5>
          <div class="badge-container">
            <b-badge
              v-for="(resource, index) in getEnumValues('ResourceType')"
              :key="index"
              variant="primary"
              class="badge-item"
            >
              {{ $t(resource) }}
            </b-badge>
          </div>
        </div>
      </b-tab>
      <b-tab :title="$t('authorizableActions')">
        <div class="mt-3">
          <h5>{{ $t('authorizableActions') }}</h5>
          <div class="badge-container">
            <b-badge
              v-for="(action, index) in getEnumValues('AuthorizableActions')"
              :key="index"
              variant="success"
              class="badge-item"
            >
              {{ $t(action) }}
            </b-badge>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs';
import { mapGetters, mapActions } from 'vuex';
import Multiselect from 'vue-multiselect';
import ConfirmDeleteButton from '@/components/ConfirmDeleteButton';
// import ButtonBar from '@/components/ButtonBar.vue';

export default {
  name: 'PolicyEditPage',
  components: {
    Breadcrumbs,
    Multiselect,
    ConfirmDeleteButton,
  },
  data() {
    return {
      tabIndex: 0,
      filters: {
        role: null,
        department: null,
        resourceType: null,
      },
      sortOption: null,
      sortOptions: [
        {
          value: { field: 'userConditions.role', order: 1 },
          label: this.$t('byRole'),
          icon: 'fa fa-user',
        },
        {
          value: { field: 'userConditions.department', order: 1 },
          label: this.$t('byDepartment'),
          icon: 'fa fa-building',
        },
        {
          value: { field: 'resourceConditions.resourceType', order: 1 },
          label: this.$t('byResourceType'),
          icon: 'fa fa-database',
        },
        {
          value: { field: 'number', order: -1 },
          label: this.$t('newestFirst'),
          icon: 'fa fa-arrow-down',
        },
        {
          value: { field: 'number', order: 1 },
          label: this.$t('oldestFirst'),
          icon: 'fa fa-arrow-up',
        },
      ],
      policyTableStateConfig: {
        name: 'policies',
        customFilters: {
          resourceType: { value: null },
        },
        filters: {
          effect: { value: null },
        },
        sorting: {
          sortField: 'userConditions.role',
          sortOrder: 1,
        },
        pagination: {
          page: 0,
          pageSize: 100,
        },
      },
      policy: {
        id: '_new',
        name: 'New Policy',
      },
      currentPage: 1,
      perPage: 10,
      // Define special policy conditions
      ANYONE: 'ANYONE',
      OWNER: 'OWNER',
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters(['getEnumValues', 'getTableState']),
    ...mapGetters('policies', ['getPolicies', 'getPolicyCount']),
    tableStateStore() {
      return this.getTableState(this.policyTableStateConfig.name);
    },
    policyCount() {
      return this.getPolicyCount;
    },
    // Get enum values with special conditions added
    roleOptions() {
      return [...this.getEnumValues('UserRole'), this.ANYONE, this.OWNER];
    },
    departmentOptions() {
      return [...this.getEnumValues('Department'), this.ANYONE];
    },
    filteredPolicies() {
      return this.getPolicies.filter((policy) => {
        const matchesRole =
          !this.filters.role ||
          (policy.userConditions && policy.userConditions.role === this.filters.role);
        const matchesDepartment =
          !this.filters.department ||
          (policy.userConditions && policy.userConditions.department === this.filters.department);
        const matchesResourceType =
          !this.filters.resourceType ||
          (policy.resourceConditions &&
            policy.resourceConditions.resourceType === this.filters.resourceType);

        return matchesRole && matchesDepartment && matchesResourceType;
      });
    },
    breadcrumbs() {
      return [
        { name: 'Home', route: { name: 'home' } },
        {
          name: this.$t('accessControl'),
        },
      ];
    },
  },
  methods: {
    ...mapActions(['fetchEnumValues', 'initTableState']),
    ...mapActions('policies', [
      'fetchPolicyById',
      'updatePolicy',
      'createPolicy',
      'deletePolicy',
      'fetchPolicies',
    ]),

    // Handle filter changes
    handleFiltersChange() {
      this.currentPage = 1; // Reset to first page when filters change
      this.loadTableData();
    },

    // Reset all filters
    resetFilters() {
      this.filters = {
        role: null,
        department: null,
        resourceType: null,
      };
      this.handleFiltersChange();
    },

    // Handle sort option change
    handleSortChange(sortOption) {
      if (sortOption && sortOption.value) {
        this.tableStateStore.sorting.sortField = sortOption.value.field;
        this.tableStateStore.sorting.sortOrder = sortOption.value.order;
        this.loadTableData();
      }
    },

    /**
     * Load remote table data
     */
    async loadTableData() {
      await this.fetchPolicies({
        pagination: {
          skip: this.tableStateStore.skip(),
          pageSize: this.tableStateStore.pagination.pageSize,
        },
        sorting: this.tableStateStore.sorting,
        filters: { ...this.tableStateStore.filters, ...this.tableStateStore.customFilters },
      });
    },

    // Handler for policy edit button
    editPolicy(policy) {
      console.log('Edit policy:', policy);
      // Here you would implement logic to open policy editor or navigate to policy edit route
      // For example:
      // this.$router.push({ name: 'PolicyEditDetails', params: { policyId: policy._id } });
    },

    async toggleActionCondition(policy, action) {
      const updatedPolicy = { ...policy };

      // console.log('toggleActionCondition():', updatedPolicy, policy, action);

      if (!updatedPolicy.actionConditions) {
        updatedPolicy.actionConditions = [];
      }
      if (updatedPolicy.actionConditions.includes(action)) {
        updatedPolicy.actionConditions = updatedPolicy.actionConditions.filter((a) => a !== action);
      } else {
        updatedPolicy.actionConditions.push(action);
      }

      await this.createOrUpdatePolicy(updatedPolicy);
    },

    async createOrUpdatePolicy(policy) {
      try {
        if (!policy.number || policy.number === '_new') {
          await this.createPolicy(policy);
        } else {
          await this.updatePolicy(policy);
        }
      } catch (error) {
        console.error('Error creating or updating policy:', error);
      }
    },

    async updateResourceType(policy, resourceType) {
      const updatedPolicy = { ...policy };

      // Initialize resourceConditions if it doesn't exist
      if (!updatedPolicy.resourceConditions) {
        updatedPolicy.resourceConditions = {};
      } else {
        updatedPolicy.resourceConditions = { ...updatedPolicy.resourceConditions };
      }

      updatedPolicy.resourceConditions.resourceType = resourceType;
      await this.createOrUpdatePolicy(updatedPolicy);
    },

    async updateUserRole(policy, role) {
      const updatedPolicy = { ...policy };

      // Initialize userConditions if it doesn't exist
      if (!updatedPolicy.userConditions) {
        updatedPolicy.userConditions = {};
      } else {
        updatedPolicy.userConditions = { ...updatedPolicy.userConditions };
      }

      updatedPolicy.userConditions.role = role;
      await this.createOrUpdatePolicy(updatedPolicy);
    },

    async updateUserDepartment(policy, department) {
      const updatedPolicy = { ...policy };

      // Initialize userConditions if it doesn't exist
      if (!updatedPolicy.userConditions) {
        updatedPolicy.userConditions = {};
      } else {
        updatedPolicy.userConditions = { ...updatedPolicy.userConditions };
      }

      updatedPolicy.userConditions.department = department;
      await this.createOrUpdatePolicy(updatedPolicy);
    },

    async toggleAssignmentCondition(policy) {
      const updatedPolicy = { ...policy };

      // Initialize resourceConditions if it doesn't exist
      if (!updatedPolicy.resourceConditions) {
        updatedPolicy.resourceConditions = {};
      } else {
        updatedPolicy.resourceConditions = { ...updatedPolicy.resourceConditions };
      }

      updatedPolicy.resourceConditions.userHasToBeAssignedToResource = !updatedPolicy
        .resourceConditions.userHasToBeAssignedToResource;

      await this.createOrUpdatePolicy(updatedPolicy);
    },

    getPolicyDescription(policy) {
      if (!policy.userConditions && !policy.resourceConditions && !policy.actionConditions) {
        return this.$t('newPolicy');
      }

      const parts = [];

      // Add role part
      if (policy.userConditions?.role) {
        if (policy.userConditions.role === this.ANYONE) {
          parts.push(this.$t('anyRole'));
        } else if (policy.userConditions.role === this.OWNER) {
          parts.push(this.$t('resourceOwner'));
        } else {
          parts.push(this.$t(policy.userConditions.role));
        }
      }

      // Add department part
      if (policy.userConditions?.department) {
        if (policy.userConditions.department === this.ANYONE) {
          parts.push(this.$t('anyDepartment'));
        } else {
          parts.push(this.$t('in') + ' ' + this.$t(policy.userConditions.department));
        }
      }

      // Add resource part with assignment condition
      if (policy.resourceConditions?.resourceType) {
        let resourcePart =
          this.$t('can_access') + ' ' + this.$t(policy.resourceConditions.resourceType);
        if (policy.resourceConditions.userHasToBeAssignedToResource) {
          resourcePart += ' (' + this.$t('when_assigned') + ')';
        }
        // Add ownership context if this is an owner policy
        if (policy.userConditions?.role === this.OWNER) {
          resourcePart += ' (' + this.$t('own_resources') + ')';
        }
        parts.push(resourcePart);
      }

      // Add actions part
      if (policy.actionConditions && policy.actionConditions.length > 0) {
        const actions = policy.actionConditions.map((action) => this.$t(action)).join(', ');
        parts.push(actions);
      }

      // If no parts are available, return default name
      if (parts.length === 0) {
        return this.$t('_new');
      }

      return parts.join(' ');
    },

    async createNewPolicy() {
      const newPolicy = {
        description: '',
        effect: 'ALLOW',
        priority: 1,
        userConditions: {
          role: this.filters.role || null,
          department: this.filters.department || null,
        },
        resourceConditions: {
          resourceType: this.filters.resourceType || null,
          userHasToBeAssignedToResource: false,
        },
        actionConditions: [],
        contextConditions: {},
      };

      try {
        await this.createPolicy(newPolicy);

        // Set sort to newest first to show the new policy at the top
        this.sortOption = this.sortOptions[3]; // This is the newestFirst option
        this.tableStateStore.sorting.sortField = 'number';
        this.tableStateStore.sorting.sortOrder = -1;

        await this.loadTableData(); // Refresh the policies list
      } catch (error) {
        console.error('Error creating new policy:', error);
      }
    },
  },

  async created() {
    await this.initTableState(this.policyTableStateConfig);
  },

  async mounted() {
    await this.fetchEnumValues('AuthorizableActions');
    await this.fetchEnumValues('UserRole');
    await this.fetchEnumValues('ResourceType');
    await this.fetchEnumValues('Department');

    // Set default sort option to newest first
    this.sortOption = this.sortOptions[0];

    await this.loadTableData();
  },
};
</script>

<style scoped lang="scss">
// Roles tab styling
.roles-container {
  max-width: 800px;
}

.role-item {
  padding: 10px 0;
}

.role-description {
  color: #6c757d;
  font-size: 0.9rem;
}

// Create new policy button styling
.btn-primary {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  font-weight: 500;

  i {
    font-size: 14px;
  }
}
.policy-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  margin-bottom: 1em;
}

.policy-section {
  border-radius: 4px;
  flex: 1 1 300px;
  min-width: 340px;
  max-width: 650px;
  margin-bottom: 0;
  position: relative;

  .delete-btn {
    opacity: 0.6;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 1;
    }
  }
}

@media (min-width: 992px) {
  .policy-section {
    flex: 0 1 calc(50% - 1em);
  }
}

@media (min-width: 1200px) {
  .policy-section {
    flex: 0 1 calc(33.333% - 1em);
  }
}

.policy-card {
  background-color: white;
  border-radius: 4px;
  padding: 0.5em 1em;
}

.policy-card-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.policy-info-row {
  display: flex;
  align-items: flex-start;
  padding: 8px 0;
  border-bottom: 1px solid #eee;

  &:last-child {
    border-bottom: none;
  }
}

.policy-info-label {
  flex: 0 0 120px;
  font-weight: 600;
  color: #666;
}

.policy-info-value {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.action-checkbox-group {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.action-checkbox-item {
  flex: 0 0 auto;
  min-width: 120px;
}

.badge-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
}

.badge-item {
  font-size: 1rem;
  padding: 8px 12px;
  margin-bottom: 5px;
  text-align: left;
  width: fit-content;
}

.policy-name {
  word-break: break-word;
  overflow-wrap: break-word;
  max-width: calc(100% - 50px);
  font-size: 0.9rem;
  color: #666;
}

.p-card {
  border: 1px solid #dedede;
}

// Multiselect styling
.gap-3 {
  gap: 1rem !important;
}

::v-deep .multiselect {
  min-width: 200px;
}

::v-deep .multiselect__tags {
  min-height: 40px;
  padding: 8px 40px 0 8px;
  border-radius: 4px;
  border: 1px solid #ced4da;
  background: #fff;
}

::v-deep .multiselect__select {
  height: 38px;
}

::v-deep .multiselect__placeholder {
  margin-bottom: 8px;
  padding-top: 0;
}

::v-deep .multiselect--active {
  z-index: 1000;
}

::v-deep .multiselect__single {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  padding-top: 2px;
}
</style>
